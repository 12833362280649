import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Button, BackTop, ConfigProvider, Input, Affix, Row, Col, Popover, Carousel, Form, Checkbox,
    Radio,
    List,
    Empty,
    Spin,
    Rate,
    Steps,
    DatePicker,
    Tabs
  } from 'ant-design-vue'


import 'ant-design-vue/dist/antd.less';

import '@/styles/index.less'


createApp(App)
.use(Button)
.use(BackTop)
.use(ConfigProvider)
.use(Input)
.use(Affix)
.use(Row)
.use(Col)
.use(Popover)
.use(Carousel)
.use(Form)
.use(Checkbox)
.use(Radio)
.use(List)
.use(Empty)
.use(Spin)
.use(Rate)
.use(Steps)
.use(DatePicker)
.use(Tabs)
.use(store).use(router).mount('#app')
