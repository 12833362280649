<template>
  <a-carousel arrows :dots="false">
    <template #prevArrow>
      <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
        <left-outlined />
      </div>
    </template>
    <template #nextArrow>
      <div class="custom-slick-arrow" style="right: 10px">
        <right-outlined />
      </div>
    </template>

    <div class="banner_img">
      <img src="@/assets/imgs/banner1.jpg" alt="" />
      <div
        class="banner_des d-flex align-items-center justify-content-center flex-column"
      >
        <h2 class="title text-white font-weight-bold">做有价值的培训 做有温度的分享</h2>
        <div class="banner_des_content">为党政机关、街道、企事业单位和个人提供一站式线上线下优质培训服务及人才发展解决方案</div>
      </div>
    </div>
    <!-- <div class="banner_img">
      <img src="@/assets/imgs/banner2.jpg" alt="" />
      <div
        class="banner_des d-flex align-items-center justify-content-center flex-column"
      >
        <h2 class="title text-white font-weight-bold">天府人才学校</h2>
        <div class="banner_des_content">为党政机关、街道、企事业单位和个人提供一站式线上线下优质培训服务及人才发展解决方案</div>
      </div>
    </div> -->
  </a-carousel>
</template>
<script>
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    LeftOutlined,
    RightOutlined,
  },
  setup() {
    let list = []
    setTimeout(() => {
      list = ['./a']
    }, 800)
  },
})
</script>
<style lang="less" scoped>
.banner_img {
  min-width: 1280px;
  // min-height: calc(~'100vh - 80px');
  min-height: 100vh;
  position: relative;
  & > img {
    // width: auto;
    width: 100%;
    min-width: 100%;
    // min-height: calc(~'100vh - 80px');
      min-height: 100vh;
  }
  .banner_des {
    width: 100%;
    // height: calc(~'100vh - 80px');
      height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    color: @white-color;
    .title {
      font-size: 56px;
      text-shadow: 0 0 5px @primary-color;
    }
    .banner_des_content {
      font-size: 28px;
    }
  }
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  // height: calc(~'100vh - 80px');
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 48px;
  height: 48px;
  font-size: 48px;
  color: #fff;
  opacity: 0.3;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>