<template>
    <div class="header_wrapper">
      <header class="header" >
        <div class="d-flex justify-content-between body__content_box">
          <div class="logo_box"><img src="@/assets/logo.png" alt=""></div>
          <div class="company_name text-xl ml-md text-white">成都天府新区人才发展职业培训学校</div>
          <div class="nav_bar text-right flex-1">
            <router-link to="/">首页</router-link>
            <router-link to="/product">产品服务</router-link>
            <router-link to="/about">关于我们</router-link>
          </div>
        </div>
      </header>
    </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.header_wrapper {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}
.header {
  height: 80px;
  background: rgba(0, 0, 0, 0.2);
  .logo_box {
    margin-top: 12px;
    max-width: 133px;
    max-height: 56px;
    overflow: hidden;
    img {
      // width: 1;
      // height: 56px;
      // max-width: 100%;
      // max-height: 100%;
    }
  }
  .company_name {
    line-height: 80px;
  }
  .nav_bar {
    height: 80px;
    line-height: 80px;
    & > a {
        display: inline-block;
        height: 80px;
        padding: 0 20px;
        line-height: 80px;
        font-size: 20px;
        color: @white-color;

        // &:hover {
        //   color: @primary-color;
        //   background-color: @white-color;
        // }

        &.router-link-active {
          color: @primary-color;
          background-color: @white-color;
          border-bottom: 2px solid @primary-color;
        }
      }
  }
}
</style>
