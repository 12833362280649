<template>
  <div class="footer_wrapper">
    <div class="footer_content body__content_box text-white">
      <div class="footer_links_wrapper d-flex jusitify-content-between">
        <div class="links_body flex-1">
          <router-link to="/product"><span class="text-white text-md font-weight-bold">产品服务</span></router-link>
          <router-link to="/product"><span class="text-white text-md font-weight-bold">客户案例</span></router-link>
          <router-link to="/about"><span class="text-white text-md font-weight-bold">关于我们</span></router-link>
          <!-- <router-link to="/privacy"><span class="text-white text-md font-weight-bold">隐私政策</span></router-link> -->
        </div>
        <div class="links_tel text-right text-primary font-weight-bold text-lg">官方电话：400-061-6661</div>
      </div>

      <div class="footer_select_wrapper">
        <a-row>
          <a-col :span="20">
            <div class="website_info text-xs">
              <a-row>
                <!-- <a-col :span="8"><span class="text-666">违法和不良信息举报电话：19980468375‬</span></a-col> -->
                <a-col :span="24"><span class="text-666">邮箱：admin@cdtfhr.com</span></a-col>
                <a-col :span="24"><a href="/" class="text-666">地址：四川省成都市天府新区湖畔路西段99号天府菁蓉中心D区B7栋天府英才中心7楼</a></a-col>
                <!-- <a-col :span="8"><span class="text-666">邮箱：admin@cdtfhr.com</span></a-col>
                <a-col :span="16"><span class="text-666">固定电话：400-061-6661</span></a-col> -->
                <a-col :span="24"><span class="text-666">2014-2021© 成都天府新区人才发展职业培训学校</span></a-col>
               
                <a-col :span="24"><span class="text-666"></span> <a class="text-666" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">蜀ICP备2021007735号</a> </a-col>
                <a-col :span="24"><span class="text-666"></span> <a class="text-666" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51012202000925" target="_blank">川公网安备 51012202000925号</a> <img src="@/assets/imgs/ga.png" style="width:20px;height:20px;"/></a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="3" :offset="1">
            <div class="qr_box text-right"><img src="@/assets/imgs/px_qr_code.jpg" alt=""></div>
          </a-col>
        </a-row>
      </div>

    </div>

  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    let selectedIndex = ref(1);

    const changeSelcetIndex = (index) => {
      selectedIndex.value = index;
    }

    return {
      selectedIndex,
      changeSelcetIndex
    }
  },
})
</script>
<style lang="less">
  .footer_wrapper {
    background-color: #2a2a2a;
    // background-color: @primary-color;
    padding: 30px 0;

    .footer_links_wrapper {
      height: 50px;
      border-bottom: 1px solid @grey-color;
      .links_body {
        & > a {
          margin-right: 30px;
        }
      }
      .links_tel {
        width: 300px;
      }
    }
    .footer_select_wrapper {
      margin-top: 24px;
      .select_wrapper {

        .select_title {
          .select_item {
            padding: 6px 14px;
            margin-right: 30px;
            background-color: #121212;
          }
          .select_item_active {
            color: @white-color !important;
          }
        }
        .select_content {
          .select_result_box {
            .select_item {
              line-height: 30px;
            }
          }
        }
      }
      .website_info {
        margin-top: 10px;
        line-height: 30px;
      }

      // 二维码
      .qr_box {
        margin-top: 10px;
        &>img {
          width: 100px;
          height: 100px;
          overflow: hidden;
        }
      }
    }
  }
</style>