<template>
  <div class="home">
        <header-v />
    <div class="banner_wrapper">
      <banner />
    </div>
    <div class="content_wrapper body__content_box mt-lg mb-lg">
      <div class="py-md">
        <a-row>
          <a-col :span="24">
            <div class="content_item d-flex justify-content-between">
              <div class="content_item_imgbox d-flex align-items-center"><img src="@/assets/imgs/item1.jpg" alt=""></div>
              <div class="content_item_des pl-lg d-flex align-items-center justify-content-end">
                <div class="item_des_cc">
                  <p class="text-indent">党政人才培训主要依托国有企业资源，服务于各政府机关、街道和企事业单位，开展党政类培训工作和专业服务。培训以问题解决为导向，以满足组织培养需求、工作岗位需求和个人发展需求为目标，遵循学员成长和学习规律来设计培训课程和组织培训教学，目前已形成了系统科学、内容丰富、特色鲜明的课程体系。课程涵盖理想信念主题教育、党性培训、党务工作培训，政策精神解读政府职能建设、公职人员素质提升、高校定制专题培训班等。</p>
               </div>
              </div>
            </div>
          </a-col>
          <a-col :span="24">
            <div class="content_item d-flex justify-content-between">
              <div class="content_item_des pl-lg d-flex align-items-center justify-content-end">
                <div class="item_des_cc">
                  <p class="text-indent">企业人才培训依托成都天府新区投资集团资源禀赋，广泛结合国内知名院校，国内外知名机构，建立了具有前瞻性、时效性、针对性的特色课程体系，涵盖党建、公开课、内训、标杆考察、论坛沙龙、拓展、E-Learning等培训内容，以专业优质的服务，为广大企事业单位的人才开发与培养提供专业支持。</p>
               </div>
              </div>
              <div class="content_item_imgbox d-flex align-items-center"><img src="@/assets/imgs/item3.jpg" alt=""></div>
            </div>
          </a-col>
          <a-col :span="24">
            <div class="content_item d-flex justify-content-between">
              <div class="content_item_imgbox d-flex align-items-center"><img src="@/assets/imgs/px01.jpg" alt=""></div>
              <div class="content_item_des pl-lg d-flex align-items-center justify-content-end">
                <div class="item_des_cc">
                  <p class="text-indent">职业技能培训以提升人才专业化和职业化水平，增强企业人力资本竞争力为主要目标，紧紧围绕成都先进制造业、现代服务业和新经济“5+5+1”重点领域，产业园区和重点企业对技能人才的需求，提供职业能力测评、职业技能培训和职业技能鉴定一体化服务。</p>
                  <p class="text-indent">天府人才学校始终坚持以客户为导向，量身定做专业化服务方案，累计开展公开课、外训、沙龙等各类培训超过625场，覆盖约20000人次。未来，天府人才学校将坚持“不忘初心、砥砺前行”，以专业、优质、可靠的服务助力广大客户卓越发展。</p>
                 </div>
              </div>
            </div>
          </a-col>
          <a-col :span="24">
            <div class="content_item d-flex justify-content-between">
              <div class="content_item_des pl-lg d-flex align-items-center justify-content-end">
                <div class="item_des_cc">
                  <p class="text-indent">为提升街道工作人员综合业务水平，增强对街道工作重点及自身工作认识，加快实施乡村振兴战略，突出抓好大美乡村建设。特组织街道工作人员赴成都市成都周边乡镇进行对标，学习先进经验，助力公园城市建设。</p>
                </div>
              </div>
              <div class="content_item_imgbox d-flex align-items-center"><img src="@/assets/imgs/px02.jpg" alt=""></div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="py-md">
        <div class="content_module__ py-md">
          <div class="module_title text-center mb-lg">主题教育</div>
          <a-row :gutter="[16, 16]">
            <a-col :span="9">
              <div class="theme_edu_item">
                <img src="@/assets/imgs/theme_bg1.jpg" alt="">
                <div class="edu_item_text text-white text-center">党政人才培训</div>
              </div>
            </a-col>
            <a-col :span="9">
              <div class="theme_edu_item">
                <img src="@/assets/imgs/theme_bg2.jpg" alt="">
                <div class="edu_item_text text-white text-center">廉洁作风</div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="theme_edu_item">
                <img src="@/assets/imgs/theme_bg3.jpg" alt="">
                <div class="edu_item_text text-white text-center">忆苦思甜</div>
              </div>
            </a-col>
            <a-col :span="7">
              <div class="theme_edu_item">
                <img src="@/assets/imgs/theme_bg4.jpg" alt="">
                <div class="edu_item_text text-white text-center">企业人才培训</div>
              </div>
            </a-col>
            <a-col :span="10">
              <div class="theme_edu_item">
                <img src="@/assets/imgs/theme_bg5.jpg" alt="">
                <div class="edu_item_text text-white text-center">职业技能培训</div>
              </div>
            </a-col>
            <a-col :span="7">
              <div class="theme_edu_item">
                <img src="@/assets/imgs/theme_bg6.jpg" alt="">
                <div class="edu_item_text text-white text-center">主题教育</div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div class="content_module__ pt-lg service_wrapper bg-grey-dark">
      <div class="module_title text-dark text-center mb-lg">企业服务</div>
      <div class="service_des body__content_box">
        成都天府新区人才发展职业培训学校（以下简称“天府人才学校”）是成都天投集团旗下从事人才发展与教育培训的专业服务机构，致力于为党政机关、街道、企事业单位和个人提供一站式线上线下优质培训服务及人才发展解决方案。天府人才学校充分融合国家级新区发展优势与国有企业资源禀赋，整合国内外高校、智库机构、标杆企业等资源，汇聚专业师资队伍，打造特色课程体系，建立起党政人才培训、企业人才培训、职业技能培训三大核心业务。
     </div>
    
    </div>
    <footer-c />
  </div>
</template>

<script>
import HeaderV from '@/components/Header'
import Banner from './components/Banner'
import FooterC from '@/components/FooterC'
import { ref } from 'vue'

export default {
  name: 'Home',
  components: {
    HeaderV,
    Banner,
    FooterC
  },
  setup() {
    const top = ref(0)

    const isChange = ref(false);

    const navBarChange = (e) => {
      console.log(e, 'eee');
      isChange.value = e;
    }

    return {
      top,
      isChange,
      navBarChange
    }
  }
}
</script>

<style lang="less" scoped>
.banner_wrapper {
  width: 100%;
  // height: calc(~'100vh - 80px');
  height: 100%;
  min-height: 400px;
}
.content_wrapper {
  .content_item {
    .content_item_imgbox {
      width: 480px;
      height: 480px;
      overflow: hidden;
      transition: all 0.3s linear;
       -webkit-transition: all 0.3s linear;
      &:hover {
        transform: rotate(10deg);
      }
      img {
        width: 100%;
      }
    }
    .content_item_des {
      height: 480px;
      .item_des_cc {
        width: 600px;
        font-size: 20px;
        line-height: 36px;
        color: @grey-color;
      }
    }
  }

  
}
.content_module__ {
    .module_title {
      font-size: 36px;
      font-style: italic;
      text-shadow: 0 0 3px @grey-color;
    }
    .theme_edu_item {
      width: 100%;
      height: 160px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        max-height: 100%;
      }
      .edu_item_text {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 160px;
        line-height: 160px;
        font-size: 32px;
        background: rgba(0, 0, 0, 0.5);
        transition: all 0.3s linear;
        -webkit-transition: all 0.3s linear;
      }

      &:hover .edu_item_text {
        opacity: 1;
      }
    }
    .service_des {
      font-size: 24px;
      text-align: justify;
      text-indent: 2em;
    }
  }
  .service_wrapper {
    margin-top: 60px;
    padding-bottom: 50px;
  }
  .text-indent {
    text-indent: 2em;
  }
</style>
